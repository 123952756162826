<template>
  <div style="margin: 20px;">
    <h3>Memorandum<a type="text" style="font-size: 14px; color: green;" @click="add()"> New <i class="fas fa-plus-square"/></a></h3>
    <el-row>
      <el-alert
        v-if="request_data_add > 0"
        :title="'(' + request_data_add + ') New memorandum added. Click refresh button to reload.'"
        type="success"
        show-icon
      />
    </el-row>
    <el-row>
      <el-alert
        v-if="request_data_update > 0"
        :title="'(' + request_data_update + ') Memorandum updated. Click refresh button to reload.'"
        type="success"
        show-icon
      />
    </el-row>
    <el-card shadow="never">
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form :inline="true" label-position="right">
            <el-form-item>
              <el-date-picker v-model="year_s" type="year" size="medium" placeholder="Year" style="width: 100px" clearable />
            </el-form-item>
            <el-form-item>
              <el-select v-model="unit_id_s" size="medium" placeholder="Unit" style="width: 130px" clearable>
                <el-option v-for="item in units" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input v-model="subject_s" size="medium" placeholder="Subject" style="width: 300px" clearable />
            </el-form-item>
            <el-form-item>
              <el-tooltip content="Search" placement="top">
                <el-button :icon="icon_search" type="primary" size="medium" @click="search()" />
              </el-tooltip>
            </el-form-item>
            <el-form-item>
              <el-tooltip content="Refresh" placement="top">
                <el-button :icon="icon_refresh" type="info" size="medium" @click="refresh()" />
              </el-tooltip>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" align="right">
          <el-form :inline="true" label-position="right">
            <el-form-item>
              <el-checkbox v-model="naf">No Attached File</el-checkbox>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-table v-loading="loading" ref="singleTable" :data="memo" size="mini" style="width: 100%; font-size: 14px;" highlight-current-row @current-change="handleCurrentChange">
          <el-table-column type="expand" label="..." width="48">
            <template slot-scope="props">
              <el-row>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <p class="para"><strong>Unit: </strong>{{ props.row.department.acronym }} - {{ props.row.department.fullname }}</p>
                  <p class="para"><strong>Memo: </strong>{{ props.row.memo }}</p>
                  <p class="para"><strong>Date: </strong>{{ getFormattedDate(props.row.date,'MMMM DD, Y') }}</p>
                  <p class="para"><strong>Memo no.: </strong>{{ props.row.memo_no }}</p>
                  <p style="word-break: break-word" class="para"><strong>Subject: </strong>{{ props.row.subject }}</p>
                  <p v-if="props.row.personnel" class="para"><strong>Signatory: </strong>{{ props.row.personnel.fullname }}</p>
                  <p v-if="props.row.doc_code" class="para"><strong>Document code: </strong>{{ props.row.doc_code }}</p>
                  <p v-if="props.row.rev_num" class="para"><strong>Revision no.: </strong>{{ props.row.rev_num }}</p>
                  <p v-if="props.row.imp_date" class="para"><strong>Implementation date: </strong>{{ getFormattedDate(props.row.imp_date,'MMMM DD, Y') }}</p>
                  <p v-if="props.row.con_num" class="para"><strong>Control no.: </strong>{{ props.row.con_num }}</p>
                  <p v-if="props.row.add_by_personnel" class="para">
                    <strong>Added by: </strong>
                    <span :title="getFormattedDate(props.row.created_at, 'MMMM Do YYYY, hh:mm:ss a')">{{ props.row.add_by_personnel.name }}</span>
                  </p>
                  <p v-if="props.row.update_by_personnel" class="para">
                    <strong>Updated by: </strong>
                    <span :title="getFormattedDate(props.row.updated_at, 'MMMM Do YYYY, hh:mm:ss a')">{{ props.row.update_by_personnel.name }}</span>
                  </p>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column label="Year" width="100">
            <template slot-scope="scope">
              {{ $moment(new Date(scope.row.date)).format('YYYY') }}
            </template>
          </el-table-column>
          <el-table-column label="Unit" width="100">
            <template slot-scope="scope">
              {{ scope.row.department.acronym }}
            </template>
          </el-table-column>
          <el-table-column prop="memo_no" label="Memo no." width="100" />
          <el-table-column label="Subject" width="800">
            <template slot-scope="scope">
              <span v-if="scope.row.subject.length < 100" style="word-break: break-word"> {{ scope.row.subject }} </span>
              <span v-else style="word-break: break-word"> {{ scope.row.subject.substring(0, 100) + " ..." }} </span>
            </template>
          </el-table-column>
          <el-table-column width="" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.path" style="word-break: break-word"><el-tag type="success" effect="plain" size="medium" style="color: #4CAF50 !important; border-color: #4CAF50 !important; width: 120px;">File Uploaded</el-tag></span>
              <span v-else style="word-break: break-word"><el-tag type="danger" effect="plain" size="medium" style="width: 120px;">No Attached File</el-tag></span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="Operation (s)" width="150" align="right">
            <template slot-scope="scope">
              <el-button-group>
                <el-tooltip v-if="scope.row.path" :key="'iftpm1' + scope.row.id" content="View Attached File" placement="top">
                  <el-button :key="'bnm2' + scope.row.id" type="primary" icon="el-icon-document" size="mini" style="margin-right: 10px;" @click="viewFile(scope.row.path)" />
                </el-tooltip>
                <el-tooltip :key="'tpm3' + scope.row.id" content="Edit" placement="top">
                  <el-button :key="'bnm4' + scope.row.id" type="primary" icon="el-icon-edit" size="mini" style="margin-right: 10px;" @click="edit(scope.$index)" />
                </el-tooltip>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
        <div align="center" style="margin-top: 10px;">
          <el-pagination :page-size="meta.per_page" :pager-count="5" :total="meta.total" :current-page.sync="meta.page" layout="total, prev, pager, next" @current-change="getAll()" />
        </div>
      </el-row>
    </el-card>
    <el-dialog v-if="selected" :visible.sync="editable" :title="title" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" top="5vh" width="70%">
      <el-row style="margin: -50px 0;">
        <el-divider />
        <p><strong>Note:</strong> Fields marked with asterisk (<span style="color: #f56c6c; font-weight: bold;">*</span>) are required to be filled.</p>
        <el-form ref="selected" :model="selected" :rules="rules">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="8">
              <el-form-item label="Unit" prop="unit_id">
                <el-select v-if="editable" v-model="selected.unit_id" size="medium" placeholder="Please select unit" style="width: 100%" clearable>
                  <el-option v-for="item in units" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
              <el-form-item label="Date" prop="date">
                <el-date-picker v-if="editable" v-model="selected.date" type="date" size="medium" placeholder="Please pick a date" style="width: 100%;" clearable />
              </el-form-item>
              <el-form-item label="Memo no." prop="memo_no">
                <el-input v-if="editable" v-model.number="selected.memo_no" type="number" min="1" size="medium" placeholder="Please input memo no." style="width: 100%;" clearable />
              </el-form-item>
              <el-form-item label="Subject" prop="subject">
                <el-input v-if="editable" v-model="selected.subject" :autosize="{ minRows: 6 }" type="textarea" maxlength="500" show-word-limit placeholder="Please input subject" style="width: 100%; word-break: keep-all;" />
              </el-form-item>
              <el-form-item label="Signatory">
                <select-personnel v-if="editable" :post="selected.personnel" @setPersonnel="selected.personnel_id = $event" />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="8">
              <el-form-item label="Document code">
                <el-input v-model="selected.doc_code" size="medium" placeholder="Please input document code" style="width: 100%;" clearable />
              </el-form-item>
              <el-form-item label="Revision no.">
                <el-input v-model.number="selected.rev_num" type="number" min="1" size="medium" placeholder="Please input revision no." style="width: 100%;" clearable />
              </el-form-item>
              <el-form-item label="Implementation date">
                <el-date-picker v-model="selected.imp_date" type="date" size="medium" placeholder="Please input implementation date" style="width: 100%;" clearable />
              </el-form-item>
              <el-form-item label="Control no.">
                <el-input v-model="selected.con_num" size="medium" placeholder="Please input control no." style="width: 100%;" clearable />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="8">
              <el-form-item label="Attached File">
                <memo-uploader-component v-if="editable" :path.sync="selected.path" class="my-upload-job" @setAttachment="selected.path = $event" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider />
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="medium" @click="submitForm('selected')">Save</el-button>
        <el-button type="danger" size="medium" @click="submitClose('selected')">Cancel</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="view_attach_form"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="closeFile"
      width="60%"
      top="5vh">
      <div>
        <embed :key="view_file" :src="view_file" style="width: 100%; height: 700px;">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { MEMO } from '@/api/records/memo'
import { Notification } from 'element-ui'
import SelectPersonnel from '@/views/layout/components/SelectPersonnel'
import MemoUploaderComponent from '@/views/layout/components/Records/MemoUploaderComponent'

export default {
  name: 'Memorandum',
  components: { SelectPersonnel, MemoUploaderComponent },
  data() {
    return {
      loading: false,
      meta: {
        page: 1
      },
      memo: [],
      editable: false,
      title: null,
      personnel: null,
      units: [
        { value: 58, label: 'OP' },
        { value: 60, label: 'OVPI' },
        { value: 127, label: 'OVPAA' },
        { value: 59, label: 'OVPAF' },
        { value: 91, label: 'OVPPRGEA' },
        { value: 61, label: 'OVPRE' }
      ],
      memorandum: 'Memorandum',
      year_s: null,
      unit_id_s: null,
      subject_s: null,
      naf: false,
      path_s: null,
      selected: {
        unit_id: [],
        date: '',
        memo_no: '',
        subject: '',
        personnel_id: null,
        doc_code: '',
        rev_num: '',
        imp_date: '',
        con_num: '',
        path: ''
      },
      rules: {
        unit_id: [
          { required: true, message: 'Unit is required' }
        ],
        date: [
          { required: true, message: 'Date is required', trigger: 'change' }
        ],
        memo_no: [
          { required: true, message: 'Memo no. is required' },
          { type: 'number', message: 'Memo no. must be a number' }
        ],
        subject: [
          { required: true, message: 'Subject is required', trigger: 'blur' },
          { max: 500, message: 'Length should be 500', trigger: 'blur' }
        ]
      },
      request_data_add: 0,
      request_data_update: 0,
      cur_personnel_id: this.$store.getters.user.currentUser.id,
      base_url: this.$store.getters.base_url,
      icon_search: 'el-icon-search',
      icon_refresh: 'el-icon-refresh',
      view_file: null,
      view_attach_form: false
    }
  },
  watch: {
    naf: function() {
      this.meta.page = 1
      if (this.naf === true) {
        this.year_s = null
        this.unit_id_s = null
        this.subject_s = null
        this.path_s = 1
      } else {
        this.year_s = null
        this.unit_id_s = null
        this.subject_s = null
        this.path_s = null
      }
      this.getAll()
    }
  },
  created: function() {
    this.$echo.channel('memo').listen('RecordsMemoEvent', (res) => {
      this.action = res.data
      if (this.action.action === 'add') {
        if (this.action.action_by !== this.cur_personnel_id) {
          if (this.action.action_memo === this.memorandum) {
            this.request_data_add = this.request_data_add + 1
          }
        }
      }
      if (this.action.action === 'edit') {
        if (this.action.action_by !== this.cur_personnel_id) {
          if (this.action.action_memo === this.memorandum) {
            this.request_data_update = this.request_data_update + 1
          }
        }
      }
    })
    this.getAll()
  },
  destroyed() {
    this.$echo.leave('memo')
  },
  methods: {
    getAll() {
      this.loading = true
      this.selected = null
      if (this.year_s !== null) {
        this.year_s = this.getFormattedYear(this.year_s)
      }
      MEMO({
        page: this.meta.page,
        year: this.year_s,
        unit_id: this.unit_id_s,
        memo: this.memorandum,
        subject: this.subject_s,
        path: this.path_s
      }).then(res => {
        this.memo = res.data.memo
        this.meta = res.data.meta
        this.loading = false
        this.icon_search = 'el-icon-search'
        this.icon_refresh = 'el-icon-refresh'
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    search() {
      this.icon_search = 'el-icon-loading'
      this.meta.page = 1
      this.getAll()
    },
    refresh() {
      this.icon_refresh = 'el-icon-loading'
      this.year_s = null
      this.unit_id_s = null
      this.subject_s = null
      this.naf = false
      this.path_s = null
      this.request_data_add = 0
      this.request_data_update = 0
      this.meta.page = 1
      this.getAll()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save()
        } else {
          Notification.error({
            title: 'Error',
            message: 'Unable to save',
            duration: 5 * 1000
          })
          return false
        }
      })
    },
    submitClose(formName) {
      this.$refs[formName].resetFields()
      this.editable = false
    },
    // viewFile(file) {
    //   window.open(this.base_url + '/records/memo/download/' + file, '_blank')
    // },
    viewFile(path) {
      this.view_file = this.base_url + '/records/memo/download/' + path
      this.view_attach_form = true
    },
    closeFile() {
      this.view_file = null
      this.view_attach_form = false
    },
    add() {
      this.title = 'New Memorandum'
      this.selected = {}
      this.selected.orig_path = null
      this.editable = true
    },
    edit(index) {
      this.title = 'Edit Memorandum'
      this.selected = Object.assign({}, this.memo[index])
      this.selected.orig_path = this.selected.path
      this.editable = true
    },
    save: function() {
      if (this.selected.unit_id === 58) this.unit_name = 'OP'
      if (this.selected.unit_id === 60) this.unit_name = 'OVPI'
      if (this.selected.unit_id === 127) this.unit_name = 'OVPAA'
      if (this.selected.unit_id === 59) this.unit_name = 'OVPAF'
      if (this.selected.unit_id === 91) this.unit_name = 'OVPPRGEA'
      if (this.selected.unit_id === 61) this.unit_name = 'OVPRE'
      this.selected.memo = this.memorandum
      this.selected.date = this.getFormattedDate(this.selected.date)
      if (this.selected.imp_date) {
        this.selected.imp_date = this.getFormattedDate(this.selected.imp_date)
      }
      var action = this.selected.id ? 'update' : 'create'
      MEMO(this.selected, action).then(res => {
        if (res.data.error === true) {
          Notification.error({
            title: 'Error',
            message: 'Data already existed',
            duration: 5 * 1000
          })
        } else {
          Notification.success({
            title: 'Success',
            message: 'Memorandum has been saved successfully',
            duration: 5 * 1000
          })
          this.submitClose('selected')
          this.getAll()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getFormattedTimeStamps() {
      return this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    },
    getFormattedDate(date, format = 'YYYY-MM-DD') {
      return this.$moment(date).format(format)
    },
    getFormattedYear(year) {
      return this.$moment(new Date(year)).format('YYYY')
    },
    handleCurrentChange(val) {
      this.currentRow = val
    }
  }
}

</script>
